import React, { useState, useEffect } from "react";
import { Button, Image, Progress, Typography, Spin } from "antd";
import "./itinerary.css";
import DestinationInput from "./widgets/destinationInputs/destinationInputs";
import { CaretDown } from "@phosphor-icons/react";
import ItineraryHeader from "./itineraryHeader/itineraryHeader";
import { useLocation } from "react-router-dom";
import NumberOfTraveller from "./widgets/numberOfTraveller/numberOfTraveller";
import Budget from "./widgets/budget/budget";
import Purpose from "./widgets/purpose/purpose";
import CalendarInputs from "./widgets/calendarInputs/calendarInputs";
import axios from "axios";
import ItineraryResult from "./itineraryResult/itineraryResult";
import dayjs from "dayjs";

const getTravellerCount = (value = 0) => {
  if (value > 4) return "Family";
  if (value >= 3) return "Friends";
  if (value === 2) return "Partner";
  return "Solo";
};

const getTravellerCountLabel = (value = "") => {
  if (value === "Family") return 5;
  if (value === "Friends") return 4;
  if (value === "Partner") return 2;
  return 1;
};

const getBudgetValue = (value) => {
  if (value > 75000) return "Luxury Redefined";
  if (value > 50000) return "Travel in Comfort";
  return "Value Getaways";
};

const getBudgetValueNumber = (value = "") => {
  if (value === "Luxury Redefined") return 76000;
  if (value === "Travel in Comfort") return 55000;
  return 25000;
};

const { Text } = Typography;
function TravelSteps() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(20);
  const [formData, setaFormData] = useState({
    destination: null,
    date: [],
    numberOfTravelers: null,
    budget: null,
    purpose: [],
  });
  const [finalData, setFinalData] = useState({
    isLoading: false,
    values: null,
  });

  const onNavigationPress = async (action) => {
    if (action === "next") {
      setCurrent(current + 20);
    } else if (action === "prev") {
      setCurrent(current - 20);
    } else {
      const _payload = {
        inputData: {
          destination: formData?.destination?.cityName,
          travelDates: {
            start: dayjs(formData?.date?.[0]).format("YYYY-MM-DD"),
            end: dayjs(formData?.date?.[1]).format("YYYY-MM-DD"),
          },
          numberOfTravelers: getTravellerCountLabel(
            formData?.numberOfTravelers?.key
          ),
          budgetRange: {
            min: getBudgetValueNumber(formData?.budget?.key),
            max: getBudgetValueNumber(formData?.budget?.key),
          },
          primaryPurpose: formData?.purpose?.[0]?.key,
        },
      };
      setIsLoading(true);
      const res = await axios.post(
        `http://13.235.91.67:8000/public/itinerary`,
        _payload
      );
      if (res?.data?.message?.length) {
        setFinalData({
          isLoading: false,
          values: res?.data,
        });
      }
      setIsLoading(false);
    }
  };

  const contentStyle = {
    textAlign: "center",
    marginTop: 16,
    flexGrow: 1,
  };
  const renderContent = (current) => {
    switch (current) {
      case 20:
        return (
          <DestinationInput
            values={formData}
            onCardClick={(val) => {
              setaFormData({
                ...formData,
                destination: val,
              });
            }}
          />
        );
      case 40:
        return (
          <CalendarInputs
            values={formData}
            onClick={(val) => {
              setaFormData({
                ...formData,
                date: val,
              });
            }}
          />
        );
      case 60:
        return (
          <NumberOfTraveller
            values={formData}
            onClick={(val) => {
              setaFormData({
                ...formData,
                numberOfTravelers: val,
              });
            }}
          />
        );
      case 80:
        return (
          <Budget
            values={formData}
            onClick={(val) => {
              setaFormData({
                ...formData,
                budget: val,
              });
            }}
          />
        );
      case 100:
        return (
          <Purpose
            values={formData}
            onClick={(val) => {
              let modifiedData = [];
              if (
                formData?.purpose?.map((val) => val?.key)?.includes(val?.key)
              ) {
                modifiedData = formData?.purpose?.filter(
                  (innerVal) => innerVal?.key !== val?.key
                );
              } else {
                modifiedData = [...formData?.purpose, val];
              }
              setaFormData({
                ...formData,
                purpose: modifiedData,
              });
            }}
          />
        );
      default:
        return <></>;
    }
  };
  const renderNextButton =
    current < 100 ? (
      <Button
        className="next-btn"
        type="primary"
        ghost
        size="large"
        onClick={() => onNavigationPress("next")}
      >
        Next
      </Button>
    ) : current === 100 ? (
      <Button
        size="large"
        className="done-btn"
        ghost
        onClick={() => onNavigationPress("done")}
      >
        Done
      </Button>
    ) : null;
  const renderPreviousButton = current > 20 && (
    <Button
      size="large"
      className="prev-btn"
      type="text"
      onClick={() => onNavigationPress("prev")}
    >
      Back
    </Button>
  );

  const getDefaultResult = async (value) => {
    try {
      setIsLoading(true);
      const res = await axios.post(
        `http://13.235.91.67:8000/public/itinerary`,
        {
          inputString: value || "",
        }
      );

      setaFormData({
        suggestedDestination: res?.data?.data?.suggestDestination || '',
        destination: res?.data?.data?.destination
          ? {
              cityName: res?.data?.data?.destination,
              country: res?.data?.data?.destination,
            }
          : null,
        date:
          res?.data?.data?.travelDates?.start &&
          res?.data?.data?.travelDates?.end
            ? [
                dayjs(res?.data?.data?.travelDates?.start),
                dayjs(res?.data?.data?.travelDates?.end),
              ]
            : [],
        numberOfTravelers: !res?.data?.data?.numberOfTravelers
          ? null
          : {
              value: getTravellerCount(res?.data?.data?.numberOfTravelers),
              key: getTravellerCount(res?.data?.data?.numberOfTravelers),
            },
        budget: !res?.data?.data?.budgetRange?.max
          ? null
          : {
              value: getBudgetValue(res?.data?.data?.budgetRange?.max),
              key: getBudgetValue(res?.data?.data?.budgetRange?.max),
            },
        purpose: [],
      });
      if (res?.data?.message?.length) {
        setFinalData({
          isLoading: false,
          values: res?.data,
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const searchedText = location?.search?.split("=")?.[1];
    if (searchedText) {
      getDefaultResult(decodeURI(searchedText));
    }
  }, [location?.search]);

  return (
    <div className="travel-steps-container">
      {/* Header */}
      <ItineraryHeader
        showShare={finalData?.values}
        onShareClick={() => {}}
        onCross={() => {
          setFinalData({
            isLoading: false,
            values: null,
          });
        }}
      />
      {isLoading || finalData?.isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : finalData?.values ? (
        <ItineraryResult data={finalData?.values?.data || ""} />
      ) : (
        <>
          <div className="progress-container">
            <Progress
              percent={current}
              showInfo={false}
              trailColor="#E5E5D8"
              strokeColor="#278F83"
              strokeWidth={8}
            />
          </div>
          <div style={contentStyle}>{renderContent(current)}</div>

          {/* Bottom */}
          <div className="travel-steps-bottom">
            <div className="currency-country">
              <div className="left-country-info">
                <Image src="/american-flag.png" width={28} height={28} preview={false}/>
                <Text className="country-name">{"United States"}</Text>
                <CaretDown size={18} color="#fff" />
              </div>
              <div className="left-currency-info">
                <Text className="country-name">{"$ USD"}</Text>
                <CaretDown size={18} color="#fff" />
              </div>
            </div>
            <div className="travel-toggle-btn">
              <div style={{ width: "fit-content" }}>{renderPreviousButton}</div>
              <div style={{ width: "fit-content" }}>{renderNextButton}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default TravelSteps;
