import { Typography, Button } from "antd";

const { Title, Text } = Typography;

function FlightBooking() {
  return (
    <div className="flight-booking-wrapper">
      <Title className="flight-booking-wrapper-title">
      Plan. Personalise.Begin.
      </Title>
      <Text>
      Plan your trip, personalize every detail, and begin your adventure with ease with JourneyGenie
      </Text>
      <Button size="large">Get Started</Button>
    </div>
  );
}

export default FlightBooking;
