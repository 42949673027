import React, { useState, useEffect } from "react";
import { Input, Typography, Tabs, Empty, Skeleton } from "antd";
import { MagnifyingGlass } from "@phosphor-icons/react";
import {
  House,
  Ticket,
  Bed,
  Airplane,
  ForkKnife,
  CheckCircle,
} from "@phosphor-icons/react";
import "./destinationInputs.css";
import { fetchImages } from "../../../../common/Utils/helper";

const { Title, Text } = Typography;

const tabItems = [
  {
    key: "popular",
    label: "Popular",
    children: null,
    icon: <House />,
  },
  {
    key: "romantic",
    label: "Romantic",
    children: null,
    icon: <Bed />,
  },
  {
    key: "relaxing",
    label: "Relaxing",
    children: null,
    icon: <Ticket />,
  },
  {
    key: "adventurous",
    label: "Adventurous",
    children: null,
    icon: <ForkKnife />,
  },
  {
    key: "exotic",
    label: "Exotic",
    children: null,
    icon: <Airplane />,
  },
  {
    key: "spiritual",
    label: "Spiritual",
    children: null,
    icon: <Airplane />,
  },
];

function DestinationInputs({ onCardClick = () => {}, values = {} }) {
  const [destinations, setDestinations] = useState({
    isLoading: false,
    values: {},
  });

  const getDestination = async (_values = {}) => {
    try {
      const destinationsArray = _values?.suggestedDestination?.split("\n");
      const destinationsWithImages = await Promise.all(
        destinationsArray.map(async (line) => {
          const cleanLine = line.replace(/^\d+\.\s*/, "").trim();
          const [cityName, country] = cleanLine
            .split(", ")
            .map((part) => part.trim());
          let image;
          try {
            image = await fetchImages(cityName, country);
          } catch (err) {
            console.log("line err", err);
          }
          return { cityName, country, image };
        })
      );
      setDestinations({
        isLoading: false,
        values: {
          data:
          destinationsWithImages?.map((val) => {
              return {
                cityName: val?.cityName || "",
                country: val?.cityName || "",
                image: val?.image || ""
              };
            }) || [],
        },
      });
    } catch (e) {
      setDestinations({
        isLoading: false,
        values: {},
      });
    }
  };

  useEffect(() => {
    getDestination(values);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="destination-title">
        <Title
          level={2}
          style={{
            textTransform: "capitalize",
          }}
        >
          {"Select Destination"}&nbsp;
          {values?.destination?.cityName
            ? `: ${values?.destination?.cityName}`
            : ""}
        </Title>
        <Text className="destination-subtitle">
          {"Start by selecting the destination you want to travel to."}
        </Text>
        <div
          className="select-destination-header"
          style={{
            display: !values?.suggestedDestination?.length && "none",
          }}
        >
          <div className="destination-header-panel-tab">
            <Tabs
              defaultActiveKey={tabItems?.[0]?.key}
              items={tabItems}
              onChange={(val) => {}}
            />
          </div>
          <div>
            <Input
              size="large"
              placeholder="Search Destinations"
              suffix={<MagnifyingGlass size={20} />}
              className="search-destination"
            />
          </div>
        </div>
      </div>
      <div
        className="destination-content-data-content"
        style={{
          display: !values?.suggestedDestination?.length && "none",
        }}
      >
        {destinations?.isLoading ? (
          <div className="destination-content-spin">
            {[...Array(10)]?.map((val) => (
              <Skeleton.Button
                active
                block
                style={{
                  height: 200,
                  borderRadius: 4,
                }}
              />
            ))}
          </div>
        ) : destinations?.values?.data?.length ? (
          <div className="destination-content-data-content-main">
            {destinations?.values?.data?.map((val, idx) => (
              <div
                className="destination-content-card"
                id={idx}
                onClick={() => {
                  onCardClick(val);
                }}
                style={{
                  height: "fit-content",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                {val?.cityName === values?.destination?.cityName && (
                  <CheckCircle
                    size={30}
                    weight="fill"
                    fill="#004945"
                    style={{
                      position: "absolute",
                      right: 5,
                      top: 5,
                    }}
                  />
                )}
                <div
                  style={{
                    background: `url(${val?.image})`,
                    height: 200,
                    backgroundSize: "cover",
                    borderRadius: 12,
                    backgroundColor: "#cecece",
                  }}
                >
                  {/* <img src={val?.image}/> */}
                </div>
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Title level={5}>{val?.cityName}</Title>
                </div>
                {/* <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  <Text>{val?.country}</Text>
                </div> */}
              </div>
            ))}
          </div>
        ) : (
          <div className="destination-content-no-data">
            <Empty />
          </div>
        )}
      </div>
    </>
  );
}

export default DestinationInputs;
