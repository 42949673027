import React, { useEffect, useState } from "react";
import { Typography, Tabs, Skeleton, Empty } from "antd";
import axios from "axios";
import { fetchImages, tabItems } from "../../../common/Utils/helper";

const { Title, Text } = Typography;

function ExploreDestination() {
  const [selectedTab, setSelectedTab] = useState(tabItems?.[0]?.key);
  const [destinations, setDestinations] = useState({
    isLoading: false,
    values: {},
  });
  const getDestination = async (_selectedTab) => {
    try {
      setDestinations({
        isLoading: true,
        values: {},
      });
      const res = await axios.get(
        `http://13.235.91.67:8000/public/recommended-destination/${_selectedTab}`
      );
      const destinationsArray = res?.data?.data?.split("\n");
      const destinationsWithImages = await Promise.all(
        destinationsArray.map(async (line) => {
          const cleanLine = line.replace(/^\d+\.\s*/, "").trim();
          const [cityName, country] = cleanLine
            .split(", ")
            .map((part) => part.trim());
          let image;
          try {
            image = await fetchImages(cityName, country);
          } catch (err) {
            console.log("line err", err);
          }
          return { cityName, country, image };
        })
      );

      setDestinations({
        isLoading: false,
        values: {
          data: destinationsWithImages || [],
        },
      });
    } catch (e) {
      setDestinations({
        isLoading: false,
        values: {},
      });
    }
  };
  

  useEffect(() => {
    getDestination(selectedTab);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="explore-destination">
      <div className="explore-destination-title">
        <Title>Explore Destinations</Title>
      </div>
      <div className="explore-destination-panel-tab">
        <Tabs
          defaultActiveKey={selectedTab}
          items={tabItems}
          onChange={(val) => {
            setSelectedTab(val);
            getDestination(val);
          }}
        />
      </div>
      <div className="explore-destination-content">
        {destinations?.isLoading ? (
          <div className="explore-destination-spin">
            {[...Array(10)]?.map((val) => (
              <Skeleton.Button
                active
                block
                style={{
                  height: 200,
                  borderRadius: 4,
                }}
              />
            ))}
          </div>
        ) : destinations?.values?.data?.length ? (
          <div className="explore-destination-data-content">
            {destinations?.values?.data?.map((val, idx) => (
              <div className="explore-destination-card" id={idx}>
                <img
                  className="explore-destination-img"
                  src={val?.image}
                  alt="explore-destination"
                />
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Title level={5}>{val?.cityName || "city"}</Title>
                </div>
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  <Text>{val?.country || ""}</Text>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="explore-destination-no-data">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
}

export default ExploreDestination;
