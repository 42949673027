import React from "react";
import "./purpose.css";
import { Typography } from "antd";

const { Title, Text } = Typography;

const topicOptions = [
  {
    value: "Leisure and Relaxation",
    key: "Leisure and Relaxation",
  },
  {
    value: "Adventure and Exploration",
    key: "Adventure and Exploration",
  },
  {
    value: "Cultural Experience",
    key: "Cultural Experience",
  },
  {
    value: "Education and Learning",
    key: "Education and Learning",
  },
  {
    value: "Family and Friends",
    key: "Family and Friends",
  },
  {
    value: "Business and Networking",
    key: "Business and Networking",
  },
  {
    value: "Medical and Wellness",
    key: "Medical and Wellness",
  },
  {
    value: "Volunteerism and Service",
    key: "Volunteerism and Service",
  },
  {
    value: "Spiritual Growth",
    key: "Spiritual Growth",
  },
  {
    value: "Photography and Art",
    key: "Photography and Art",
  },

  {
    value: "Food and Culinary Experiences",
    key: "Food and Culinary Experiences",
  },
];

const selectedTopicOptions = [];

function NumberOfTraveller({ onClick = () => {}, values = {} }) {
  return (
    <div className="destination-title">
      <Title level={2}>My Ideal Trip Has</Title>
      <Text className="destination-subtitle">Select all that apply.</Text>
      <div className="purpose-option-list">
        {[...selectedTopicOptions, ...topicOptions]?.map((val) => {
          const selectedKeys = values?.purpose?.map((_key) => _key?.key);
          return (
            <div
              className="purpose-option-list-item"
              onClick={() => onClick(val)}
              style={{
                background: selectedKeys?.includes(val?.key) && "#E9FAEE",
                borderColor: selectedKeys?.includes(val?.key) && "#278F83",
              }}
            >
              <div className="purpose-option-list-label">{val?.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NumberOfTraveller;
