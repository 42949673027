import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./common/Header";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Itinerary from "./components/itinerary";
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs'
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(advancedFormat);

function App() {
  const [isItineraryPage, setIsItineraryPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsItineraryPage(location?.pathname === "/itinerary");
  }, [location?.pathname]);

  return (
    <div className="app">
      {!isItineraryPage && <Header />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/itinerary" element={<Itinerary />} />
          {/* <Route path="/chat" element={<Chat />} />
          <Route path="/hotels" element={<Hotels />} /> */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
