import { Typography, Tabs, Input, Image } from "antd";
import { House, Ticket, Bed, Airplane, ForkKnife } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Search } = Input;

const tabItems = [
  {
    key: "Plan Travel",
    label: "Plan Travel",
    children: null,
    icon: <House />,
  },
  {
    key: "Hotels",
    label: "Hotels",
    children: null,
    icon: <Bed />,
  },
  {
    key: "Things to Do",
    label: "Things to Do",
    children: null,
    icon: <Ticket />,
  },
  {
    key: "Restaurants",
    label: "Restaurants",
    children: null,
    icon: <ForkKnife />,
  },
  {
    key: "Flights",
    label: "Flights",
    children: null,
    icon: <Airplane />,
  },
];

function SearchPanel() {
  const navigate = useNavigate();
  return (
    <div className="search-panel">
      <div className="search-panel-text">
        <Title level={1}>Your Journey Starts Here</Title>
      </div>
      <div className="search-panel-tab">
        <Tabs defaultActiveKey="1" items={tabItems} />
      </div>

      <div className="search-panel-input">
        <Search
          placeholder="Places to go, things to do, hotels..."
          allowClear
          enterButton="Search"
          size="large"
          onSearch={(val) => {
            navigate(`/itinerary?search=${encodeURI(val)}`);
          }}
        />
      </div>
      {/* <div className="search-panel-partners">
        <img src="./partners.png" alt="search-panel-partners" />
      </div> */}
      <div className="left-cloud">
        <Image src="/cloud.png" width={53} preview={false}/>
      </div>
      <div className="right-cloud">
        <Image src="/cloud.png" width={84} preview={false}/>
      </div>
      <div className="center-cloud">
        <Image src="/cloud.png" width={84} preview={false}/>
      </div>
    </div>
  );
}

export default SearchPanel;
