import React from "react";
import "./budget.css";
import { Typography } from "antd";
import { CurrencyDollar } from "@phosphor-icons/react";

const { Title, Text } = Typography;

const travelOptions = [
  {
    value: "Value Getaways",
    key: "Value Getaways",
    icon: <CurrencyDollar />,
  },
  {
    value: "Travel in Comfort",
    key: "Travel in Comfort",
    icon: (
      <>
        <CurrencyDollar />
        <CurrencyDollar />
      </>
    ),
  },
  {
    value: "Luxury Redefined",
    key: "Luxury Redefined",
    icon: (
      <>
        <CurrencyDollar />
        <CurrencyDollar />
        <CurrencyDollar />
      </>
    ),
  },
];

function NumberOfTraveller({ onClick = () => {}, values = {} }) {
  return (
    <div className="destination-title">
      <Title level={2}>Who all will be joining you on the trip?</Title>
      <Text className="destination-subtitle">Select one.</Text>
      <div className="budget-option-list">
        {travelOptions?.map((val) => (
          <div
            className="budget-option-list-item"
            onClick={() => onClick(val)}
            style={{
              background: values?.budget?.key === val?.key && "#E9FAEE",
              borderColor: values?.budget?.key === val?.key && "#278F83",
            }}
          >
            <div className="budget-option-list-icon">{val?.icon}</div>
            <div className="budget-option-list-label">{val?.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NumberOfTraveller;
