import React from "react";

function Review() {
  return (
    <div className="review">
      <img src={"/review.png"} alt="review" />
    </div>
  );
}

export default Review;
