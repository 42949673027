import "./itineraryHeader.css";
import { Typography, Button, Image } from "antd";
import { X, Share } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

function ItineraryHeader({
  showShare = false,
  onShareClick = () => {},
  onCross = () => {},
}) {
  const navigate = useNavigate();

  return (
    <div className="itinerary">
      <div className="itinerary-header-logo">
        <div className="itinerary-header-title-logo">
          <div className="itinerary-header-logo">
            <Image src="/logo-black.png" width={44} preview={false} />
          </div>
          <Text className="itinerary-header-name">{"JourneyGenie"}</Text>
        </div>
      </div>
      <div
        className="itinerary-middle"
        style={{
          marginRight: 50,
        }}
      >
        <Title level={2}>{"Your Travel Plan"}</Title>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: 10,
        }}
      >
        {showShare && (
          <div>
            <Button
              style={{
                backgroundColor: "#004449",
                color: "#fff",
                borderRadius: 16,
              }}
              icon={<Share />}
              type="primary"
              ghost
              size="large"
              onClick={() => {
                onShareClick();
              }}
            >
              Share
            </Button>
            ;
          </div>
        )}
        <div
          className="itinerary-header-avatar"
          onClick={() => {
            navigate("/");
            onCross();
          }}
        >
          <X size={24} color="#000" />
        </div>
      </div>
    </div>
  );
}

export default ItineraryHeader;
