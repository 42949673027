import { Image , Typography} from "antd";
import "./footer.css";

const {Text}= Typography;

const footerOptions = [
  {
    title: "Services",
    data: ["B2B SaaS Design", "UX Consulting", "UI/UX Design"],
  },
  {
    title: "Portfolio",
    data: ["Dribbble", "Behance"],
  },
  {
    title: "Social",
    data: ["X (Twitter)", "Linkedin"],
  },
  {
    title: "Contact",
    data: ["Get a free quotation", "Book a call", "Book a session"],
  },
];
const logoStyle={
  width: "20%",
  display: "flex",
  justifyContent: "center",
  flexDirection:'column',
  alignItems:'center'
}
function Footer() {
  return (
    <div className="footer">
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {footerOptions?.map((val) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 10,
            }}
          >
            <div
              style={{
                marginBottom: 10,
                opacity: 0.6,
              }}
            >
              {val?.title}
            </div>
            {val?.data?.map((innerVal) => (
              <div>{innerVal}</div>
            ))}
          </div>
        ))}
      </div>
      <div
        style={logoStyle}
      ><Image preview={false} width={96} src="/logo-lemon.png" className="logo-img"/><Text className="logo-text">{'JourneyGenie'}</Text></div>
    </div>
  );
}

export default Footer;
