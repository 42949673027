import React, { useState } from "react";
import "./numberOfTraveller.css";
import { Typography } from "antd";
import {
  Users,
  PersonSimpleRun,
  HeartHalf,
  UsersFour,
  PawPrint,
  Check,
} from "@phosphor-icons/react";

const { Title, Text } = Typography;

const travelOptions = [
  {
    value: "Solo",
    key: "Solo",
    icon: <PersonSimpleRun />,
  },
  {
    value: "Partner",
    key: "Partner",
    icon: <HeartHalf />,
  },
  {
    value: "Friends",
    key: "Friends",
    icon: <Users />,
  },
  {
    value: "Family",
    key: "Family",
    icon: <UsersFour />,
  },
];
const petsOptions = [
  {
    value: "Yes",
    key: "yes",
    icon: <Check />,
  },
  {
    value: "No",
    key: "no",
    icon: <Check />,
  },
];

function NumberOfTraveller({ onClick = () => {}, values = {} }) {
  const [isPets, setIsPets] = useState(null);
  return (
    <div className="destination-title">
      <Title level={2}>Who all will be joining you on the trip?</Title>
      <Text className="destination-subtitle">Select one.</Text>
      <div className="travel-option-list">
        {travelOptions?.map((val) => (
          <div
            className="travel-option-list-item"
            onClick={() => onClick(val)}
            style={{
              background:
                values?.numberOfTravelers?.key === val?.key && "#E9FAEE",
              borderColor:
                values?.numberOfTravelers?.key === val?.key && "#278F83",
            }}
          >
            <div className="travel-option-list-icon">{val?.icon}</div>
            <div className="travel-option-list-label">{val?.value}</div>
          </div>
        ))}
      </div>
      <div
        style={{
          marginTop: "40px",
        }}
      >
        <div
          style={{
            marginBottom: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PawPrint />
          <Text
            className="destination-subtitle"
            style={{
              margin: 0,
              marginLeft: 10,
            }}
          >
            Are you travelling with pets?
          </Text>
        </div>
        <div
          className="travel-option-list"
          style={{
            marginTop: 0,
          }}
        >
          {petsOptions?.map((val) => (
            <div
              className="travel-option-list-item"
              onClick={() => {
                setIsPets(val);
              }}
              style={{
                background: isPets?.key === val?.key && "#E9FAEE",
                borderColor: isPets?.key === val?.key && "#278F83",
                borderRadius: 24,
                minWidth: 50,
              }}
            >
              {isPets?.key === val?.key && (
                <div className="travel-option-list-icon">{val?.icon}</div>
              )}
              <div className="travel-option-list-label">{val?.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NumberOfTraveller;
