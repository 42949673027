import "./itineraryResult.css";
import Markdown from "react-markdown";
import { Typography, Tabs, Spin } from "antd";
import remarkGfm from "remark-gfm";
import {
  Calendar,
  CurrencyDollar,
  Users,
  LightbulbFilament,
} from "@phosphor-icons/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { fetchImages } from "../../../common/Utils/helper";

const getBudgetValue = (value) => {
  if (value > 75000) return "Luxury Redefined";
  if (value > 50000) return "Travel in Comfort";
  return "Value Getaways";
};

const { Title } = Typography;

const tabItems = [
  {
    key: "overview",
    label: "Overview",
    children: null,
    // icon: <House />,
  },
  {
    key: "activities",
    label: "Activities",
    children: null,
    // icon: <Bed />,
  },
];

function ItineraryResult({ data = {} }) {
  const [selectedTab, setSelectedTab] = useState(tabItems?.[0]?.key);
  const [locationImage, setLocationImage] = useState("");
  const [activities, setActivities] = useState({
    isloading: false,
    values: {},
  });

  const getActivities = async (_data) => {
    try {
      setActivities({
        isloading: true,
        values: {},
      });
      const res = await axios.post(
        `http://13.235.91.67:8000/public/recommended-activity`,
        {
          destination: _data?.destination,
          purpose: _data?.primaryPurpose,
          budget: _data?.budgetRange?.max,
          days:
            dayjs(_data?.travelDates?.end).diff(
              dayjs(_data?.travelDates?.start),
              "day"
            ) || 0,
          people: _data?.numberOfTravelers || 1,
        }
      );
      setActivities({
        isloading: false,
        values: res?.data,
      });
    } catch (e) {
      setActivities({
        isloading: false,
        values: {},
      });
    }
  };

  const getLocationImage = async (_city = "") => {
    try {
      setLocationImage("");
      const res = await fetchImages(_city);
      setLocationImage(res || "");
    } catch (e) {
      setLocationImage("");
    }
  };

  useEffect(() => {
    getActivities(data);
    if (data?.destination) {
      getLocationImage(data?.destination);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="itinerary-result">
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "calc(100% - 600px)",
            marginRight: 20,
          }}
        >
          <div
            style={{
              textTransform: "capitalize",
            }}
          >
            <Title level={2}>{data?.destination || "-"}</Title>
          </div>
          {data?.description && (
            <div
              style={{
                textTransform: "capitalize",
                fontSize: 16,
                marginTop: 10,
              }}
            >
              <div>{data?.description || ""}</div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              rowGap: 10,
              columnGap: 10,
            }}
          >
            <div
              style={{
                border: "1px solid #B3B3B3",
                borderRadius: 12,
                width: "fit-content",
                padding: 10,
                marginTop: 10,
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
              }}
            >
              <Calendar
                style={{
                  marginRight: 10,
                }}
              />
              {`${data?.travelDates?.start} - ${data?.travelDates?.end}`}
            </div>

            <div
              style={{
                border: "1px solid #B3B3B3",
                borderRadius: 12,
                width: "fit-content",
                padding: 10,
                marginTop: 10,
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
              }}
            >
              <CurrencyDollar
                style={{
                  marginRight: 10,
                }}
              />
              {getBudgetValue(data?.budgetRange?.max)}
            </div>

            <div
              style={{
                border: "1px solid #B3B3B3",
                borderRadius: 12,
                width: "fit-content",
                padding: 10,
                marginTop: 10,
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
              }}
            >
              <Users
                style={{
                  marginRight: 10,
                }}
              />
              Traveller: {data?.numberOfTravelers}
            </div>

            <div
              style={{
                border: "1px solid #B3B3B3",
                borderRadius: 12,
                width: "fit-content",
                padding: 10,
                marginTop: 10,
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
              }}
            >
              <LightbulbFilament
                style={{
                  marginRight: 10,
                }}
              />
              Purpose: {data?.primaryPurpose}
            </div>
          </div>
          <div className="itinerary-tab">
            <Tabs
              defaultActiveKey={selectedTab}
              items={tabItems}
              onChange={(val) => {
                setSelectedTab(val);
              }}
            />
          </div>
          {activities?.isloading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 400,
              }}
            >
              <Spin />
            </div>
          ) : selectedTab === "overview" ? (
            <div>
              {/* <div
            style={{
              whiteSpace: "pre-line",
              padding: 20,
              backgroundColor: "#f1f1f1",
              borderRadius: 12,
              fontSize: 16,
              marginTop: 10,
            }}
          >
            {data?.itinerary || ""}
          </div> */}
              <div
                className="markdown-custom-style"
                style={{
                  padding: 20,
                  // backgroundColor: "#f1f1f1",
                  color: "#004449",
                  borderRadius: 12,
                  marginTop: 10,
                }}
              >
                <Markdown remarkPlugins={[remarkGfm]}>
                  {data?.itinerary || ""}
                </Markdown>
              </div>
            </div>
          ) : (
            <div>
              {/* <div
            style={{
              whiteSpace: "pre-line",
              padding: 20,
              backgroundColor: "#f1f1f1",
              borderRadius: 12,
              fontSize: 16,
              marginTop: 10,
            }}
          >
            {activities?.values?.data || ""}
          </div> */}
              <div
                className="markdown-custom-style"
                style={{
                  padding: 20,
                  // backgroundColor: "#f1f1f1",
                  color: "#004449",
                  borderRadius: 12,
                  marginTop: 10,
                }}
              >
                <Markdown remarkPlugins={[remarkGfm]}>
                  {activities?.values?.data || ""}
                </Markdown>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            width: 600,
            height: 400,
          }}
        >
          {locationImage?.length && (
            <img
              // className="explore-destination-img"
              src={locationImage}
              alt="explore-destination"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 12,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ItineraryResult;
