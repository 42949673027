import axios from "axios";
import { House, Ticket, Bed, Airplane, ForkKnife } from "@phosphor-icons/react";

export const fetchImages = (cityName, country) => {
    return axios
      .get(
        `https://api.unsplash.com/search/photos?query=${cityName}{' '}${country}&client_id=A0c4g3cqIS_WlhM3rIbKXbXak0nIQ_Yz0Kf9msBF770`
      )
      .then((res) => {
        console.log(
          "this.state.gallery = ",
          res?.data?.results?.[0]?.urls?.regular
        );
        return res?.data?.results?.[0]?.urls?.regular;
      })
      .catch((error) =>{ console.log(error);  return `/cities/city${getUniqueRandomNumbers(1,10)}.jpg`});
  };

export const getUniqueRandomNumbers=(count, max)=> {
    const uniqueNumbers = new Set();
  
    while (uniqueNumbers.size < count) {
        const randomNumber = Math.floor(Math.random() * max) + 1; // Random number between 1 and max
        uniqueNumbers.add(randomNumber); // Set will handle uniqueness
    }
  
    return Array.from(uniqueNumbers);
  }
  
export const tabItems = [
    {
      key: "popular",
      label: "Popular",
      children: null,
      icon: <House />,
    },
    {
      key: "romantic",
      label: "Romantic",
      children: null,
      icon: <Bed />,
    },
    {
      key: "relaxing",
      label: "Relaxing",
      children: null,
      icon: <Ticket />,
    },
    {
      key: "adventurous",
      label: "Adventurous",
      children: null,
      icon: <ForkKnife />,
    },
    {
      key: "exotic",
      label: "Exotic",
      children: null,
      icon: <Airplane />,
    },
    {
      key: "spiritual",
      label: "Spiritual",
      children: null,
      icon: <Airplane />,
    },
  ];
  