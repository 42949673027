import "./header.css";
import { User } from "@phosphor-icons/react";
import { Avatar, Typography, Image } from "antd";
const { Text } = Typography;
// import { useNavigate } from "react-router-dom";

// const menuItems = [
//   {
//     name: <Title level={3}>Home</Title>,
//     route: "/",
//     id: "Home",
//     icon: <House size={26} />,
//   },
//   {
//     name: <Title level={3}>Chat</Title>,
//     route: "/chat",
//     id: "Chat",
//     icon: <Chat size={26} />,
//   },
//   {
//     name: <Title level={3}>Hotels</Title>,
//     route: "/hotels",
//     id: "Hotels",
//     icon: <Bed size={26} />,
//   },
// ];

function Header() {
  // const navigate = useNavigate();
  return (
    <div className="header">
      <div className="header-title-logo">
        <div className="header-logo"><Image preview={false} src="/logo.png" width={52} /></div>
        <Text className="header-name">{"JourneyGenie"}</Text>
      </div>
      {/* <div className="menu-items">
        {menuItems?.map((val) => (
          <div
            onClick={() => navigate(val?.route)}
            className={`menu-item ${
              window?.location?.pathname === val?.route && "selected-menu-item"
            }`}
          >
            <div className="menu-item-icon">{val?.icon}</div>
            <div className="menu-item-name">{val?.name}</div>
          </div>
        ))}
      </div> */}
      <div className="header-avatar">
        {" "}
        <Avatar
          style={{ backgroundColor: "#caef45" }}
          icon={<User size={24} fill="#000" />}
        />
        <Text className="header-avatar-name">{'Thai.W'}</Text>
      </div>
    </div>
  );
}

export default Header;
