import React from 'react'
import SearchPanel from "./widgets/searchPanel";
import HowGoingWorks from "./widgets/howGoingWorks";
import FlightBooking from "./widgets/flightBooking";
import ExploreDestination from "./widgets/exploreDestination";
import Review from "./widgets/review";
import Footer from "../../common/Footer";
import "./home.css";

function Home() {
  return (
    <div className="home">
      <SearchPanel />
      <HowGoingWorks />
      <FlightBooking />
      <ExploreDestination />
      <Review />
      <Footer />
    </div>
  );
}

export default React.memo(Home);
