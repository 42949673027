import React from "react";
import { Typography, DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./calendarInputs.css";
const { Title, Text } = Typography;
function CalendarInputs({ values, onClick = () => {} }) {
  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };
  const popupAlign = {
    points: ["bc", "bc"],
    offset: [0, -15],
    overflow: {
      adjustX: true,
      adjustY: true,
    },
  };
  const onDateChange = (dates) => {
    if (dates) {
      onClick(dates)
    } else {
      onClick([])
    }
  };
  return (
    <div className="destination-title">
      <Title level={2}>{"Select Dates"}</Title>
      <Text className="destination-subtitle">
        {"Specify what dates you’ll be travelling in."}
      </Text>
      <div className="destination-range-picker">
        <Space direction="vertical" size={12}>
          <RangePicker
            disabledDate={disabledDate}
            open
            popupAlign={popupAlign}
            popupStyle={{ marginTop: "100px", background: "transparent" }}
            onChange={onDateChange}
            value={values?.date}
          />
        </Space>
      </div>
    </div>
  );
}

export default CalendarInputs;
