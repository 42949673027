import { Typography } from "antd";

const { Title, Text } = Typography;

const HowGoingWorksCard = ({ name = "", desc = "", iconUrl = "" }) => {
  return (
    <div className="how-going-works-card-wrapper">
      <img src={iconUrl} alt="how-going-works-card-logo" />
      <div className="how-going-works-card">
        <Title level={3}>{name}</Title>
        <Text className="how-going-works-card-des">{desc}</Text>
      </div>
    </div>
  );
};

function HowGoingWorks() {
  return (
    <div className="how-going-works">
      <Text className="how-going-works-text">{"How JourneyGenie Works"}</Text>
      <div className="how-going-works-imgs">
        <HowGoingWorksCard
          name="Find the perfect plan"
          desc="Let our genie help you find the travel itinerary for any destination!"
          iconUrl="first-logo.svg"
        />
        <HowGoingWorksCard
          name="Then customise it"
          desc="Easily personalise it for your needs to create the perfect travel plan."
          iconUrl="second-logo.svg"
        />
        <HowGoingWorksCard
          name="Manage your journey"
          desc="Get details of stays, travel and activities."
          iconUrl="third-logo.svg"
        />
      </div>
    </div>
  );
}

export default HowGoingWorks;
